<template>
  <section class="policy-list">

    <div class="flex-sb-center header-title">
      <h1
        class="">
        iPad Ticket
      </h1>
      <div>
        <span
          class="bell-box"
          :class="{'hand':ticketNotify}"
          @click="handleClear">
          <span
            v-if="ticketNotify"
            class="dot "
          >
            <img
              src="@/assets/images/icon/dot-icon.svg"
              alt=""
            />
          </span>
          <img
            class="img-bell"
            src="@/assets/images/icon/bell-regular.svg"
            alt=""/>
        </span>
      </div>
      <!--        <div>-->
      <!--          <v-button @click="handleExport">Export</v-button>-->
      <!--        </div>-->
    </div>
    <div
    >
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick">
        <el-tab-pane
          v-for="(item,index) in ticketList"
          :key = index
          :label="item.name"
          :name="item.id"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="show-table-maxscreen">

      <el-table
        :header-row-class-name="'table-header'"
        :data="tableData"
        style="width: 100%"
        @row-click="rowClick"
      >
        <el-table-column
          v-if="showIssue"
          prop="issue_date"
          :show-overflow-tooltip="true"
          width="180">
          <template slot="header">
            <div  class="table-slot-header">

              <span>Issue Date</span>
              <v-date-picker
                v-model="issueDate"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handlerChange"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.issue_date)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showEffective"
          prop="effective_date"
          :show-overflow-tooltip="true"
          width="180">
          <template
            slot="header"
          >
            <div  class="table-slot-header">

              <span>Effective Date</span>
              <v-date-picker
                v-model="date"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handlerChange"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.effective_date)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showExipredDate"
          prop="expiry_date"
          :show-overflow-tooltip="true"
          width="180">
          <template slot="header" >
            <div  class="table-slot-header">

              <span>Expiry Date</span>
              <v-date-picker
                v-model="expiryDate"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handlerChange"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.expiry_date)}}</span>
          </template>
        </el-table-column>


        <el-table-column
          v-if="showCreateDate"
          width="180">
          <template slot="header" >
            <div  class="table-slot-header">

              <span>Create Date</span>
              <v-date-picker
                v-model="createdDate"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handlerChange"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <p>{{scope.row.created_date}}</p>
            <p class="liter-text gray-color fs-12">{{scope.row.created_time}}</p>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showPeriod"
          :show-overflow-tooltip="true"
          prop="date"
          width="180">
          <template slot="header" >
            <div  class="table-slot-header">
              <span>Policy Period</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.effective_date)+'-'+ showMonth(scope.row.expiry_date)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showType"
          width="180">
          <template slot="header" >
            <div  class="table-slot-header">
              <span>Policy Type</span>
              <v-select
                v-model="policy_type"
                :list="policyTypeList"
                @change="handlerChange"></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{ policyType(scope.row.policy_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isShowDraft"
          :show-overflow-tooltip="true"
          width="120"
        >
          <template
            slot="header"
          >
            <div  class="table-slot-header">
              <span>Created by</span>
              <v-select
                v-model="created_by"
                :list="createdByList"
                @change="handlerChange"
              ></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="flex">
              <span  class="text-over-flow">{{getOptionName('globalOptions.created_by',scope.row.created_by)}}</span>
            </div>

            <!--            <span>{{getOptionName('globalOptions.company',scope.row.company_id)}}</span>-->
          </template>
        </el-table-column>
        <el-table-column
          v-if="isShowTicket"
          :show-overflow-tooltip="true"
          width="150"
          prop="serial_number"
        >
          <template
            slot="header"
          >
            <div  class="table-slot-header">
              <span>Ticket No.</span>
              <v-input
                v-model="serial_number"
                placeholder="Search"
                @change="handlerChange"
              ></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              :class="{'red-color':scope.row.is_red_number}"
              class="text-blold">{{scope.row.serial_number}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showEffective"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template
            slot="header"
          >
            <div  class="table-slot-header">
              <span>Insurer</span>
              <v-select
                v-model="company_id"
                :list="companyList"
                @change="handlerChange"
              ></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="flex flex-logo">
              <!--              <span-->
              <!--                v-if="showOtherInsurer(scope.row.company_id)"-->
              <!--                class="company-logo">-->
              <!--                <img-->
              <!--                  :src="getOptionData('insureSVGOptions',scope.row.company_id).logo"-->
              <!--                  alt="company" />-->
              <!--              </span>-->
              <span  class="text-over-flow">{{scope.row.company_name}}</span>

            </div>

            <!--            <span>{{getOptionName('globalOptions.company',scope.row.company_id)}}</span>-->
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="180"
          label="Product">
          <template
            slot="header"
          >
            <div  class="table-slot-header">
              <span>Product</span>
              <v-select
                v-model="product_id"
                :list="productList"
                @change="handlerChange"
              ></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{getOptionName('productTypeOptions',scope.row.product_id)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="220px"
          :show-overflow-tooltip="true"
          prop="policy_holder"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Policyholder</span>
              <v-input
                v-model="search_content"
                placeholder="Search"
                @change="handlerSearch(2)"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              class="cell-wrap"
              :class="{'red-color':scope.row.blacklist}">{{scope.row.policy_holder}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="type==1||type==7"
          width="220px"
          :show-overflow-tooltip="true"
          prop="policy_no"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Policy No.</span>
              <v-input
                v-model="search_content"
                placeholder="Search"
                @change="handlerSearch(3)"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span  class="text-over-flow">{{scope.row.policy_no||'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showType||isRefund"
          :show-overflow-tooltip="true"
          prop="contact_no"
          width="120"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Contact No.</span>
              <v-input
                v-model="search_content"
                placeholder="Search"
                key-number
                :maxlength="8"
                @change="handlerSearch(1)"></v-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          min-width="120"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Status</span>
              <v-select
                v-model="status "
                :list="allStatusList"
                @change="handlerChange"
              ></v-select>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              class="span-options"
              :class="{
                'dark':scope.row.status==2,
                'green':scope.row.status==3,
                'primary':scope.row.status==1,
                'red':scope.row.status==4,
              }"
            >{{ getOptionName('globalOptions.status',scope.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Payment</span>
            </div>
          </template>
          <template slot-scope="{row}">
            <template v-if="row.payment">
              <p>{{row.payment.amount_received|formatMoney(2,true)}}</p>
              <p class="liter-text gray-color2 fs-12">{{row.payment.type_name}}</p>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Description</span>
            </div>
          </template>
          <template slot-scope="{row}">
            <template v-if="row.desc">
              <span
                v-for="(item,index) in row.desc"
                :key="'desc_'+index">
                <span>{{ item }} {{(index+1)==row.desc.length?'':', '}}</span>
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="200"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Remark</span>
            </div>
          </template>
          <template slot-scope="scope">
            <p class="text-over-flow ">{{scope.row.internal_remark}}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <el-row>
                <v-row-col v-if="isShowDraft">
                  <span class="is-bold">Ticket No.</span>
                  <v-input
                    v-model="serial_number"
                    placeholder="Search"
                    @change="handlerChange"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Date Range</span>
                  <div class="margin">
                    <v-select
                      v-model="selectDate"
                      :list="selectDateOptions"></v-select>
                  </div>
                  <v-date-picker
                    v-model="date"
                    type="daterange"
                    :isPicker="true"
                    @change="handlerChange"
                  />
                </v-row-col>
                <v-row-col v-if="showIssue">
                  <span class="is-bold">Issue Date</span>
                  <div class="margin">
                    <v-date-picker
                      v-model="issueDate"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      :isPicker="true"
                      @change="handlerChange"
                    />
                  </div>
                </v-row-col>
                <v-row-col v-if="showEffective">
                  <span class="is-bold">Effective Date</span>
                  <div class="margin">
                    <v-date-picker
                      v-model="exffectiveDate"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      :isPicker="true"
                      @change="handlerChange"
                    />
                  </div>
                </v-row-col>
                <v-row-col v-if="showExipredDate">
                  <span class="is-bold">Expiry Date</span>
                  <div class="margin">
                    <v-date-picker
                      v-model="expiryDate"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      :isPicker="true"
                      @change="handlerChange"
                    />
                  </div>
                </v-row-col>
                <v-row-col v-if="showCreateDate">
                  <span class="is-bold">Created Date</span>
                  <div class="margin">
                    <v-date-picker
                      v-model="createdDate"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      :isPicker="true"
                      @change="handlerChange"
                    />
                  </div>
                </v-row-col>
                <v-row-col v-if="showType">
                  <span class="is-bold">Policy Type</span>
                  <v-select
                    v-model="policy_type"
                    :list="policyTypeList"
                    @change="handlerChange"></v-select>
                </v-row-col>
                <v-row-col v-if="isShowDraft">
                  <span class="is-bold">Created by</span>
                  <v-select
                    v-model="created_by"
                    :list="createdByList"
                    @change="handlerChange"></v-select>
                </v-row-col>

                <v-row-col  v-if="showEffective">
                  <span class="is-bold">Insurer</span>
                  <v-select
                    v-model="company_id"
                    :list="companyList"
                    @change="handlerChange"
                  ></v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold" >Product</span>
                  <v-select
                    v-model="product_id"
                    :list="productList"
                    @change="handlerChange"></v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Policyholder</span>
                  <v-input
                    v-model="search_content"
                    placeholder="Search"
                    @change="handlerSearch(2)"></v-input>
                </v-row-col>
                <v-row-col v-if="type==1">
                  <span class="is-bold">Policy No.</span>
                  <v-input
                    v-model="search_content"
                    placeholder="Search"
                    @change="handlerSearch(3)"></v-input>
                </v-row-col>
                <v-row-col  v-if="showType">
                  <span class="is-bold">Contact No.</span>
                  <v-input
                    v-model="search_content"
                    placeholder="Search"
                    @change="handlerSearch(1)"></v-input>
                </v-row-col>
                <v-row-col v-if="showType">
                  <span class="is-bold">Status</span>
                  <v-select
                    v-model="status"
                    :list="globalOptions.status"
                    @change="handlerChange"></v-select>
                </v-row-col>
                <v-row-col v-if="showTell">
                  <span class="is-bold">Tell No.</span>
                  <v-input
                    v-model="search_content"
                    placeholder="Search"
                    @change="handlerSearch(1)"></v-input>
                </v-row-col>
                <v-row-col  v-if="showRefer">
                  <span class="is-bold">Referrer</span>
                  <v-input
                    v-model="search_content"
                    placeholder="Search"
                    @change="handlerSearch(4)"></v-input>
                </v-row-col>
              </el-row>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table"
        @click="rowClick(item)">
        <div
          v-if="showIssue"
          class="tr">
          <span>Issue Date</span>
          <span>{{showMonth(item.issue_date)}}</span>
        </div>
        <div
          v-if="showExipredDate"
          class="tr">
          <span>Expiry Date</span>
          <span>{{showMonth(item.expiry_date)}}</span>
        </div>
        <div
          v-if="showEffective"
          class="tr">
          <span>Effective Date</span>
          <span>{{showMonth(item.effective_date)}}</span>
        </div>

        <div
          v-if="showPeriod"
          class="tr">
          <span>Policy Period</span>
          <span>{{showMonth(item.effective_date)+'-'+ showMonth(item.expiry_date)}}</span>
        </div>
        <div
          v-if="showCreateDate"
          class="tr">
          <span>Create Date</span>
          <span>{{showMonth(item.created_date)}}</span>
        </div>
        <div
          v-if="showEffective"
          class="tr">
          <span>Insurer</span>
          <span>{{item.company_name}}</span>
        </div>

        <div
          v-if="showType"
          class="tr">
          <span>Policy Type</span>
          <span>{{policyType(item.policy_type)}}</span>
        </div>
        <div
          v-if="isShowDraft"
          class="tr">
          <span>Created by</span>
          <span>{{getOptionName('globalOptions.created_by',item.created_by)}}</span>
        </div>
        <div
          v-if="isShowTicket"
          class="tr">
          <span>Ticket No.</span>
          <span>{{item.serial_number}}</span>
        </div>
        <div
          class="tr">
          <span>Product</span>
          <span>{{getOptionName('productTypeOptions',item.product_id)}}</span>
        </div>
        <div
          class="tr">
          <span>Policyholder</span>
          <span
            :class="{'red-color':item.blacklist}">{{item.policy_holder}}</span>
        </div>
        <div
          v-if="type==1||isRefund"
          class="tr">
          <span>Policy No.</span>
          <span>{{item.policy_no||'-'}}</span>
        </div>
        <div
          v-if="showType||isRefund"
          class="tr">
          <span>Contact No.</span>
          <span>{{item.contact_no}}</span>
        </div>
        <div
          v-if="showTell"
          class="tr">
          <span>Tell No.</span>
          <span>{{item.contact_no}}</span>
        </div>
        <div
          v-if="showType"
          class="tr">
          <span>Status</span>
          <span >{{ getOptionName('globalOptions.status',item.status)}}</span>
        </div>
        <div
          class="tr">
          <span>Payment</span>
          <template v-if="item.payment">
            <span>
              <p>{{item.payment.amount_received|formatMoney(2,true)}}</p>
              <p class="liter-text gray-color2 fs-12">{{item.payment.type_name}}</p>
            </span>
          </template>
        </div>
        <div
          v-if="type==1&&all_policy_type!=6"
          class="tr">
          <span>Referrer</span>
          <span
            v-if="item.referrer.id"
            class="link"
            @click.stop="handlerReferrer(item.referrer.id)">{{item.referrer.name}}</span>
          <span v-else>NIL</span>
        </div>
        <div
          class="tr">
          <span>Description</span>
          <template v-if="item.desc">
            <span
              v-for="(items,index) in item.desc"
              :key="'desc_'+index">
              <span>{{ items }} {{(index+1)==items.desc&&items.desc.length?'':', '}}</span>
            </span>
          </template>
        </div>
        <div
          class="tr">
          <span>Remark</span>
          <span
          >{{item.internal_remark}}</span>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange"
    />
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import {getPolicyList,getPolicyListExport} from '@/api/policy'
import {getTicketList, gtTicketNotify, readTicketNotify} from '@/api/ticket'
import { mapState, mapActions } from 'vuex'
import {downloadFile} from '@/utils/download'
import MixinFormat from '@/mixins/format'

export default {
  name: 'list',
  mixins: [MixinOptions,MixinFormat],
  data(){
    return {
      typeName:'',
      policy_status:'',
      selectDate:1,
      show:false,
      search_type:'',
      content:'',
      date:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        to:'',
        per_page:1,
        size:20
      },
      tableData: [],
      exffectiveDate:'',
      expiryDate:'',
      createdDate:'',
      issueDate:'',
      company_id:'',
      product_id:'',
      search_content:'',
      all_policy_type:'0',
      created_by:'',
      serial_number:'',
      policy_type:'',
      status:2,
      type:8,
      activeName:'2'
    }
  },

  computed:{
    ...mapState('policy',['search']),
    ...mapState('common',['ticketNotify']),
    showIssue(){
      return this.type==1||this.type==5||this.type==7
    },
    isShowDraft(){
      return this.type==2||this.type==8
    },
    isShowTicket(){
      return this.type==8
    },
    isRefund(){
      return this.all_policy_type==6
    },
    showEffective(){
      return this.type==1||this.type==5||this.type==7
    },
    showRefer(){
      return this.type==1||this.type==5
    },
    showPeriod(){
      return this.type==4||this.type==6||this.type==5
    },
    showTell(){
      return this.type==4||this.type==5||this.type==6||this.type==3
    },
    showCreateDate(){
      return this.type ==4||this.type==2||this.type==8
    },
    showExipredDate(){
      return this.type ==3||this.type==1
    },
    //type\contact no\status
    showType(){
      return this.type ==1||this.type ==2||this.type==5||this.type==8
    },
    policy_status_list(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.policy_status)
    },
    companyList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.all_policy_company)
    },
    createdByList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.created_by)
    },
    productList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.product)
    },
    policyTypeList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.policy_type)
    },
    allStatusList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.status)
    },
  },
  watch: {
    search: {
      deep: true,
      immediate: true,
      handler(val) {
        let {search_content, search_type} = val || {}
        this.content = search_content || ''
        this.search_type = search_type || ''
        if(this.type==1)
        {
          this.typeName ='all'
          this.getList()
          // search_content
          // this.search_type =''
        }else{
          this.search_content=''
        }
      }
    },
    type: {
      immediate: true,
      handler (val)
      {
        if (val) {
          if(val==8)
            this.network().getTicketList()
        }
      }

    },
  },
  created() {
    this.network().readTicketNotify()
  },
  methods:{
    ...mapActions('policy', ['setSearch']),
    ...mapActions('common', ['setTicketNotify']),
    handleClear(){
      if(!this.ticketNotify)return
      this.network().readTicketNotify()
      this.handlerChange()
    },
    handleExport(){
      this.network().getPolicyListExport()
    },
    handleClick(){
      this.status = this.activeName
      this.pageConfig.currentPage = 1
      this.getList()
    },
    showOtherInsurer(company_id) {
      let arr =  [1,4,11,3]
      return arr.includes(company_id)
    },
    statusData(id){
      let status = {
        30:'Inforce ',
        40:'Cancelled',
        50:'Expired'
      }
      return status[id]
    },
    policyType(type){
      let status = {
        200:'New',
        201:'Renew'
      }
      return this.getOptionName('globalOptions.policy_type',type)
    },
    rowClick(data){
      let {policy_id,status,order_no,ticket_id}  =data||{}
      if(this.type==1){
        this.setSearch(null)
      }
      if(this.all_policy_type==5||this.all_policy_type==6){
        this.$router.push({
          path:'/quotation/premium/info',
          query:{
            order_no:order_no,
          }
        })
      }
      else if(this.type==8){
        this.$router.push({
          path:'/ticket/info',
          query:{
            ticket_id,
            policy_id
          }
        })
      }
      else
        this.$router.push({
          path:'/policy/info',
          query:{
            policy_id:policy_id,
            type:this.type,
            status,//1draft
          }
        })
    },
    handlerChange(){
      this.pageConfig.currentPage = 1
      this.getList()
    },
    handlerSearch(search_type){
      this.pageConfig.currentPage = 1
      // let params = {
      //   search_content:this.search_content,
      this.setSearch(null)
      this.search_type=search_type
      this.content=this.search_content
      // }
      // this.setSearch(params)
      this.getList()
    },
    getList(){
      if(this.isShowTicket)
        this.network().getTicketList()
      else
        this.network().getPolicyList()
    },
    handlerReferrer(id){
      this.$router.push('/member/referral/statement/'+id)
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.getList()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.getList()
    },
    network() {
      return {
        getPolicyList: async () => {

          let dataParams = {
            effective_date_start: this.date? this.date[0]: '',
            effective_date_end: this.date? this.date[1]: '',
            type: this.typeName,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
            policy_status:this.policy_status,
            // ...this.search,
            search_type:this.search_type,
            search_content:this.content,
            company_id:this.company_id,
            product_id:this.product_id,
            created_at_start:this.createdDate?this.createdDate[0]: '',
            created_at_end:this.createdDate?this.createdDate[1]: '',
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            serial_number:this.serial_number,
            created_by:this.created_by,
            policy_type:this.policy_type,
          }
          if(this.type==1){
            dataParams.all_policy_type = Number(this.all_policy_type)
          }
          const { data } = await getPolicyList(dataParams)
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        getTicketList: async () => {

          let dataParams = {
            effective_date_start: this.date? this.date[0]: '',
            effective_date_end: this.date? this.date[1]: '',
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
            policy_status:this.policy_status,
            // ...this.search,
            search_type:this.search_type,
            search_content:this.content,
            company_id:this.company_id,
            product_id:this.product_id,
            created_at_start:this.createdDate?this.createdDate[0]: '',
            created_at_end:this.createdDate?this.createdDate[1]: '',
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            serial_number:this.serial_number,
            created_by:this.created_by,
            status:this.status,
          }
          if(this.type==1){
            dataParams.all_policy_type = Number(this.all_policy_type)
          }
          const { data } = await getTicketList(dataParams)
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        getPolicyListExport: async () => {

          let dataParams = {
            effective_date_start: this.date? this.date[0]: '',
            effective_date_end: this.date? this.date[1]: '',
            type: this.typeName,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
            policy_status:this.policy_status,
            // ...this.search,
            search_type:this.search_type,
            search_content:this.content,
            company_id:this.company_id,
            product_id:this.product_id,
            created_at_start:this.createdDate?this.createdDate[0]: '',
            created_at_end:this.createdDate?this.createdDate[1]: '',
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            serial_number:this.serial_number,
            created_by:this.created_by,
            policy_type:this.policy_type,
          }
          if(this.type==1){
            dataParams.all_policy_type = Number(this.all_policy_type)
          }
          const data = await getPolicyListExport(dataParams)
          downloadFile(data)
        },
        readTicketNotify: async () => {
          const { data } = await readTicketNotify()
          this.setTicketNotify(false)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
.bell-box{
  width: 50px;
  height: 50px;
  background: $primary;
  display: block;
  text-align: center;
  border-radius: 50px;
  position: relative;
  .dot{
    img{
      top: -2px;
      position: absolute;
      right: -2px;
    }
  }
  .img-bell{
    width: 21px;
  }
}
.margin-bottom-span{
  margin-bottom: 10px;
  display: block;
}
.flex-logo{
  align-items: center;
  .company-logo{
    width: 30px;
    height: 20px;
    display: flex;
    img{
      width: 60%;
      display: block;
    }
  }
}
.header-title{
  font-size: 36px;
  color: #000;
  margin-bottom: 30px;
  margin-top: 10px;
}
::v-deep .el-tabs__header{
  margin: 0 0 30px!important;
}
.text-blold{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #00072B;
}
</style>
